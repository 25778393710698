<template>
  <v-container id="dashboard" fluid tag="section">
    <v-text-field class="mt-4" :label="$t('datatable.write_to_search')" outlined append-icon="mdi-magnify" v-model="search">
    </v-text-field>

    <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ $t("Drawer.libraryImage") }} </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="890px">

            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs"
                     v-on="on">
                {{ $t("add_new") }}
              </v-btn>
            </template>
            <v-stepper v-model="e1">
            
          
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12" flat>

                    <v-form ref="form" v-model="valid">
                        <v-container>

                          <v-row>
                            <v-col class="py-0" cols="12" sm="12" md="12">
                              <v-text-field v-model="editedItem.title.ar"  :rules="messageRules"  required
                                            :label="$t('datatable.title')+' '+$t('arbic')"
                                            outlined>
                              </v-text-field>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="12" md="12">
                              <v-text-field v-model="editedItem.title.en"  :rules="messageRules"  required
                                            :label="$t('datatable.title')+' '+$t('en')"
                                            outlined>
                              </v-text-field>
                            </v-col>
                        

                            <v-col class="py-0" cols="6" sm="12" md="6">
                              <v-text-field  :label="$t('Drawer.choose_img')"
                                            @click='pickImg' v-model='imageName'
                                            prepend-icon='fas fa-paperclip'>
                              </v-text-field>
                              <input type="file" style="display: none" ref="image"
                                     accept="image/*" @change="onFilePicked">
                            </v-col>
                                <v-col class="py-0" cols="6" sm="6" md="6">
                              <v-img :src="imageUrl" height="150" v-if="imageUrl" />
                            </v-col>



                
                          </v-row>
                        </v-container>



                    </v-form>

                  </v-card>

                  <v-btn color="primary" :loading="loadSave" @click="save()">
                    {{$t("save")}}
                  </v-btn>

                  <v-btn text @click="close()">
                    {{$t("close")}}
                  </v-btn>
                </v-stepper-content>
                      

             
              </v-stepper-items>
            </v-stepper>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button class="ml-5" @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs"
                    v-on="on">Edit </button>
          </template>
          <span>{{ $t("edit") }} </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button @click="deleteItem(item.id)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
              {{$t('Delete')}}</button>
          </template>
          <span>{{$t('Delete')}}</span>
        </v-tooltip>

      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
      </template>
    </v-data-table>
  </v-container>


</template>

<script>


import axios from "axios";
import Swal from "sweetalert2";

export default {

  name: "videos",
  data() {
    return {
      desserts: [],
      file_name:null,
      e1: 1,
      imagesUrl: '',
      imageName: '',
      imageUrl: '',
      img_old: '',
      imageFile: '',
      imagesName: ' ',
    
    
      image_url:'',
      dialog: false,
      loadSave: false,
      show_loading: false,

      editedIndex: -1,
      editedItem: {
      image: {
      image_name: ''
      },
      id: "",
      title: {
      ar: "", 
      en: ""
                    },
                },


      headers: [
        {text: 'id', value: 'id',}
        ,{
        text: this.$t('datatable.title'),
        align: "start",
        value: localStorage.getItem("lang")=='en' ?"title.en" :"title.ar"
      },
      {
          text: this.$t('Processes'),
          value: "actions",
          sortable: false
      }
      ],
      right: null,
      vvv: {},
      items: [],

    valid: false,
            rules: {
                required: value => !!value || this.$t("required_field"),
            },
      
      message:'',
       select: [v => !!v || this.$t("selectq")],
      messageRules:[
          v => !!v || this.$t("valMessage")
      ],
    }
  },
  methods: {

validate () {
        valid: true;
      },
    initialize() {
      this.loading = true;
      try {
        axios.get("libraryImage/allLibraryImage/all", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(res => {
          this.loading = false;
          this.desserts = res.data.data;

        })
            .catch(() => {
              this.loading = false;
            });
      }catch (e) {
        
      }

    },

    editItem(item) {
      this.dialog=true;
      this.e1 = 1;
      this.editedIndex = this.desserts.indexOf(item);
      this.$store.dispatch("library_uploads", item);
      this.editedItem = Object.assign({}, item);
      this.img_old = ''
      this.imageName = ''
      this.imageFile = null
      this.imageUrl = ''

      this.img_old = this.editedItem.url_image;
      this.imageUrl = this.Url + '/libraryImage/' + this.img_old;

    },
    pickFile() {
      this.$refs.file.click()
    },
    onPickfile(e) {

      this.file = this.$refs.file.files[0];
      let file = e.target.files;
      this.file = file[0]
      this.file_name = file[0].name
    },
    close() {
      this.dialog = false;
        this.e1 = 1;
this.loadSave=false;
        this.$store.dispatch("book_uploads", this.vvv);


        this.img_old = ''
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''

  
     this.editedItem = {
        image: {
      image_name: ''
      },
      id: "",
      title: {
      ar: "", 
      en: ""
        },

      };

    },
    pickImg() {
      this.$refs.image.click()
    },
    pickFiles() {
      this.$refs.images.click()

    },
    onFilePicked(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.imageUrl = fr.result
          this.imageFile = files[0]
        })
      } else {
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''
      }
    },
    deleteItem($id) {
      Swal.fire({
        title: this.$t('sure_process'),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no'),
      }).then(result => {
        if (result.value) {
          axios.delete("libraryImage/" + $id, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
              .then(() => {
                this.initialize();
                Swal.fire(this.$t('Successfully'), this.$t('done'), "success");
              })
              .catch(() => {
                Swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
              });
        }
      });
    },
 
    save() {
    if(this.$refs.form.validate()){
      
    if (this.imageFile == '') {
         this.loadSave = false;
          Swal.fire({
            title: "يجب اختيار صوره",
            text: "",
            icon: "error",
            confirmButtonText: "اغلاق",
          });
      
     }else{
      this.loadSave = true;

       if (this.editedIndex > -1) {

        
          var updatedata = {
                           
                               translations: [{
                                    title:this.editedItem.title.ar,
                                },
                                {
                                    title: this.editedItem.title.en,
                                }
                            ],
                        }
        this.axios
            .put("libraryImage/" + this.editedItem.id,updatedata, {

              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
              },
            })
            .then(() => {
              this.$refs.form.resetValidation();
              if (this.imageFile != null) {
                let formData = new FormData();
                const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                formData.append('url_image', this.imageFile);
                axios.post('libraryImage/uploadImage/' + this.editedItem.id,

                    formData, {
                      headers: {
                        Authorizations: AuthStr,
                        'Content-Type': 'multipart/form-data',
                      }
                    }
                )
                this.img_old = ''
                this.imageName = ''
                this.imageFile = ''
                this.imageUrl = ''
              }
              this.loadSave = false;

              this.initialize();
              this.close();

              Swal.fire({
                title: "تم تعديل ",
                text: "",
                icon: "success",
                confirmButtonText: "اغلاق",
              });
            })
            .catch(() => {
              this.loadSave = false;

              this.$swal.fire({
                title: "تاكد من ملى المعلومات",
                text: "",
                icon: "error",
                confirmButtonText: "اغلاق",
              });
            });
      
        
      }else{

   var savedate = {
                           
                            translations: [{
                                    title:this.editedItem.title.ar,
                                },
                                {
                                    title: this.editedItem.title.en,
                                }
                            ],
                        }

                        console.log(this.editedItem);
                        
        axios
            .post("libraryImage", savedate, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
              },
            })
            .then((res) => {
                  this.loadSave = false;
                           
                                
              this.editedItem = res.data.data;
             
              this.editedIndex = -1;
              this.$refs.form.resetValidation();
              if (this.imageFile != null) {
                let formData = new FormData();
                 const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                formData.append('url_image', this.imageFile);
                axios.post('libraryImage/uploadImage/'+ this.editedItem.id,
                    formData, {
                      headers: {
                        Authorizations: AuthStr,
                        'Content-Type': 'multipart/form-data',
                      }
                    }
              ).then(response => {
              this.imageFile='';
              this.imageFile='';
              this.imageName = '';
              this.close()
               this.initialize();
              })
                  }


              this.loadSave = false;
              this.dialog=false;

                  }).catch((err) => {
              err
              this.loadSave = false;
            });
      }
     }
     
     }
     },
  components: {

  },
  computed: {
            // UploudeImages: () => import("../../components/core/libraryupload.vue"),

},
// fdl
 
  },
   created() {
    this.initialize();
  }
  
  }
          
</script>
